<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col
        xl="9"
        lg="10"
        md="12"
      >
        <template v-if="isLoadingProducts">
          <BasicSkeleton />
          <BasicSkeleton height="500px" />
          <BasicSkeleton height="100px" />
          <BasicSkeleton height="200px" />
          <BasicSkeleton height="400px" />
          <BasicSkeleton height="100px" />
        </template>
        <template v-if="!isLoadingProducts">
          <div class="mb-2">
            <DetailsOptions
              :product="product"
              :visible="visible"
            />
          </div>
          <ExtendedDetails :product="product" />
          <ProductDescriptionCard
            :product="product"
          />
          <PricesListCard
            v-if="$ability.can('read', 'Product_prices_list')"
            :product="product"
          />
          <PricesProductCard
            v-if="$ability.can('read', 'Product_prices')"
            :product="product"
          />
          <PaginatedPiecesList
            :product="product"
            :query="query"
          />
          <DimentionsProductCard
            v-if="$ability.can('read', 'Product_dimensions')"
            :product="product"
          />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BRow, BCol, BContainer,
} from 'bootstrap-vue'

import PricesListCard from '@/modules/production/products/components/PricesListCard.vue'
import PricesProductCard from '@/modules/production/products/components/PricesProductCard.vue'
import DetailsOptions from '@/modules/production/products/components/product-card/DetailsOptions.vue'
import DimentionsProductCard from '@/modules/production/products/components/DimentionsProductCard.vue'
import ExtendedDetails from '@/modules/production/products/components/product-card/ExtendedDetails.vue'
import ProductDescriptionCard from '@/modules/production/products/components/ProductDescriptionCard.vue'
import PaginatedPiecesList from '@/modules/production/products/components/pieces/PaginatedPiecesList.vue'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'

export default {
  components: {
    BCol,
    BRow,
    BContainer,
    BasicSkeleton,
    PricesListCard,
    DetailsOptions,
    ExtendedDetails,
    PricesProductCard,
    PaginatedPiecesList,
    DimentionsProductCard,
    ProductDescriptionCard,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    visible: {
      type: [String, Number],
      default: 0,
    },
    query: {
      type: String,
      default: '',
    },
    count: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    ...mapState('products', ['product', 'isLoadingProducts']),
  },
  /**
  * Hook de la eliminación de componente
  *
  * @summary Limpia toda la informacion del producto y de las piezas
  */
  beforeDestroy() {
    this.clearPiecesInfo()
  },
  async created() {
    try {
      this.$swal({
        title: 'Cargando...',
        allowOutsideClick: false,
      })

      this.$swal.showLoading()
      await this.loadProduct({ id: this.id, count: this.count, visible: this.visible })
      this.$swal.close()
    } catch (error) {
      if (error.response.status === 404) {
        this.$router.push({ name: 'error-404' })
      }
      // this.showToast('Error de validación', getError(error), 'danger')
    } finally {
      this.$swal.close()
    }
  },
  methods: {
    ...mapActions({
      loadProduct: 'products/loadProduct',
      clearPiecesInfo: 'pieces/clearPiecesInfo',
    }),
  },
}
</script>
