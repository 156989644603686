var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"border border-dark shadow-none"},[_c('b-card-text',[_c('b-container',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('h5',{staticClass:"font-weight-bolder mb-2"},[_vm._v(" Información de las piezas ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('BasicSearcher',{attrs:{"callback":_vm.handleSearch,"placeholder":"Buscar piezas"}})],1),(_vm.pieces.length > 0)?[_c('b-col',{attrs:{"md":"12"}},[_c('b-table',{staticClass:"mt-2",attrs:{"items":_vm.pieces,"fields":_vm.columns,"responsive":""},scopedSlots:_vm._u([{key:"cell(No)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.indexFrom + data.index)+" ")]}},{key:"cell(Tag)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Tag))])]}},{key:"cell(NoSeries)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.NoSeries))])]}},{key:"cell(NoPetition)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.NoPetition))])]}},{key:"cell(NoInvoice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.NoInvoice))])]}},{key:"cell(NoImport)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.NoImport))])]}},{key:"cell(Condition)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Condition))])]}},{key:"cell(Availability)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_c('h6',[_c('b-badge',{staticClass:"ava-label",attrs:{"variant":_vm.getAvailabilityStyle(item.Availability)}},[_vm._v(_vm._s(item.Availability)+" ")])],1)])]}},{key:"cell(Location)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Location))])]}},{key:"cell(ArriveDate)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("moment")(item.ArriveDate,"DD/MM/YYYY")))])]}},{key:"cell(UnitPriceInvoiceUsd)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.UnitPriceInvoiceUsd)))])]}},{key:"cell(ExchangeRate)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.Tc)))])]}},{key:"cell(UnitPriceInvoiceMn)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.UnitPriceInvoiceMn)))])]}},{key:"cell(CostUtility)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.Ccost)))])]}},{key:"cell(ProvidersPriceUsd)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.ProvidersPriceUsd)))])]}},{key:"cell(ProvidersCostingMn)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("currencyWhite")(item.ProvidersCostingMn)))])]}}],null,false,3489439900)})],1)]:_vm._e(),(_vm.pieces.length === 0 && _vm.loadingPieces === false)?[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se encontraron piezas.")])])])],1)]:_vm._e()],2)],1)],1),(_vm.pieces.length > 0)?_c('BasicPaginator',{staticClass:"mt-2",attrs:{"callback":_vm.handleChangePagination,"total-rows":_vm.totalPieces}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }